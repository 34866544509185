import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiStar } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                <div className="col-lg-7 col-md-7 col-12 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title mt--10">Showing amazing color swatches on the product page.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Color swatch pug is a Shopify app that enables color swatches on your Shopify store</p>
                                    <div className="read-more-btn mt--40">
                                        <a className="btn-default btn-icon" href="https://apps.shopify.com/collection-swatch-pug" target="_blank">Start Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                    <p className="app-trial mb-0 mt-4"><strong> Includes 14 days free trial </strong></p>
                                    <ul className="list-icon d-flex flex-wrap">                                       
                                        <li><span className="icon"><FiStar /></span><span className="icon"><FiStar /></span><span className="icon"><FiStar /></span><span className="icon"><FiStar /></span><span className="icon"><FiStar /></span></li>
                                        <li>(Based on 100 + reviews)</li>                                       
                                    </ul>
                                </ScrollAnimation>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12">
                        <div className="thumbnail mob-img ">
                            <img className="img-fluid" src="./images/about/mob_tech.png" alt="CSP" title="Color Swatch Pug"/>
                        </div>
                    </div>

                  
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
