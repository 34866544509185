import React from 'react'
import SEO from "../common/SEO";
import Layout from '../common/Layout';

const PrivacyPolicy = ({ data }) => {
    return (
        <>
       <SEO title="BitFrills || CSP App setup guide || 2022 " />
        <Layout>          
        <div className="main-content">
            <div className="rwt-portfolio-details rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="inner">
                                <div className="portfolio-gallery mt--40">
                                {/* <Separator />  */}
                                    <div className="gallery mt--40">
                                   <div className="setup-text">
                                        <h1 className="heading theme-gradient h2">Color Swatch Pug Privacy Policy</h1>
                                        <p className="description">Color Swatch Pug "the App” provides services to manage and show Color swatches "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you 
                                           install or use the App in connection with your Shopify-supported store.</p>

                                           <h4 className="subtitle">Personal Information the App Collects</h4>
                                           <p className="description"> When you install the App, we are automatically able to access certain types of information about you and others who may access the App on behalf of your store,
                                             from your Shopify account such as name, email address, phone number, country and billing information. We do not store information about individuals who visit
                                             your shopify store.</p>

                                             <h4 className="subtitle">How Do We Use Your Personal Information?</h4>
                                             <p className="description">We use the personal information we collect from you in order to provide the Service and to operate the App. Additionally, we use this personal information to:
                                                Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.</p>

                                                <h4 className="subtitle">Sharing Your Personal Information</h4>
                                                <p className="description">Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other
                                                    lawful request for information we receive, or to otherwise protect our rights.</p>
                                                    <p className="description">If you want to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted, please contact
                                                        us through the contact information below.</p>
                                                        <p className="description">Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our
                                                         legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to India and the United States.</p>

                                                        <p className="description"><strong>Changes:</strong> We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

                                                        <p className="description"><strong>Contact Us:</strong> For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail
                                                        at <a href="mailto:contact@bitfrills.ca">contact@bitfrills.ca.</a></p>
                                    </div>
                               </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
        </Layout>
        </>
    )
}

export default PrivacyPolicy;
