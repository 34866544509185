import React from 'react';
import {Link} from "react-router-dom";
import {FiPhoneCall} from "react-icons/fi";

const Nav = () => {
    return (
        <ul className="mainmenu">   

            <li><Link to="/about-us" title="About">ABOUT</Link></li>

            <li><Link to="/portfolio" title="Portfolio">PORTFOLIO</Link></li>

            <li><Link to="/contact" title="Contact">CONTACT</Link></li>

            <li className="nav-mob"><FiPhoneCall /><a href="tel:+14378798999">+1 (437) 879-8999</a></li>

        </ul>
    )
}
export default Nav;
