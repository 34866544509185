import React, { Component } from 'react';

class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },  
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=8%20Robert%20parkinson%20dr%2C%20Brampton%2C%20Canada%20&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;