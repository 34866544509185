import React from 'react';
import { FiActivity, FiCast, FiGlobe, FiSmartphone, FiShoppingCart, FiMonitor } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'E-Commerce Development',
        description: 'Get the experience of a seasoned and veteran team of experts who cater to all your eCommerce development and Shopify store development needs.'
    },
    {
        icon: <FiGlobe />,
        title: 'Web Development',
        description: 'BitFrills creates robust, effective, and scalable solutions for web/mobile apps.'
    },
    {
        icon: <FiSmartphone />,
        title: 'App Development',
        description: 'Known as the best app development company in Canada, we offer full-stack development of mobile and web apps. We have an in-house team of designers and developers.'
    },
    {
        icon: <FiShoppingCart />,
        title: 'Migration to Shopify',
        description: 'Transferring a store to another platform can be a tough ordeal. We will make your Shopify migration safe, easy, and quick, making sure that nothing is lost along the way. '
    },
    {
        icon: <FiMonitor />,
        title: 'UI / UX Design',
        description: 'BitFrills designs the most innovative, customer-engaging, time and cost-effective web and app UI / UX designs which will help in building brand loyalty for your business.'
    },
    {
        icon: <FiCast />,
        title: 'Digital Marketing / SEO',
        description: 'Our SEO techniques are based on comprehensive research and analysis with white hat techniques we ensure long-lasting results for Search engine ranking.'
    },
   
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;