import React from 'react';
import {Link} from "react-router-dom";

const PortfolioItem = ({ portfolio }) => {
    return (
        <div className="rwt-card">
            <div className="inner">
                <div className="thumbnail">
                    <figure className="card-image">
                       <a href={portfolio.url} target="_blank">
                          <img className="img-fluid" src={portfolio.portfolioImage} alt="Portfolio-01"/>
                       </a>
                        {/* <Link to={portfolio.id}>
                            <img className="img-fluid" src={portfolio.portfolioImage} alt="Portfolio-01"/>
                        </Link> */}
                    </figure>
                </div>
                <div className="content">
                    <h5 className="title mb--10">
                    <a href={portfolio.url} target="_blank">{portfolio.title}</a>
                        {/* <Link to={portfolio.id}>{portfolio.title}</Link> */}
                    </h5>
                </div>
            </div>
        </div>
    )
}
export default PortfolioItem;