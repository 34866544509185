import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        // form: "Poland",
        description: "We feel so lucky to have found Mandeep! His work is just exceptional on every level. Fantastic communication, excellent problem solving skills, and fast task execution are just a few of his strengths. Also, he is just a pleasure to work with! We highly recommend him and will continue to work with him into the foreseeable future!.",
        name: "Julie",
        subtitle: "CEO",
        image: "nestingdays"
    },
    {
        // form: "Germany",
        description: "Mangita is excellent to work with and delivers above and beyond expectation! I would gladly work with her again very soon and would highly recommend to others.",
        name: "Robin",
        subtitle: "SEO Manager",
        image: "Male_Avatar"
    },
    {
        // form: "USA",
        description: "We are immediately rehiring Mandeep as this is an ongoing job, but we just wanted to let everyone know how amazing we think he is and that we love working with him! he has been able to accomplish everything we need help with and he is just a pleasure to work with.",
        name: "Nepoli Dewan",
        subtitle: "Shopify Store Owner",
        image: "female-avatar"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4 clients-img">
                                    <div className="thumbnail testimonial-img">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Clients Review" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















