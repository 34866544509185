import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiPhoneCall } from "react-icons/fi";

const callToActionData = {
    title: "Ready to discuss your amazing project with us?",
    subtitle: "Finest quality work with quick turnaround",
    btnText: "More Information",
    btnCall: "+1 (437) 879-8999" 
}

const CalltoActionFive = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">{callToActionData.title}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="call-to-btn text-center">
                                        {/* <a className="btn-default btn-icon" href="/contact">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a> */}
                                        <a href="tel:+14378798999" className="btn-default btn-icon cta-btn"><i className="icon"><FiPhoneCall /> </i>{callToActionData.btnCall}</a>
                                        <Link className="btn-default btn-icon" to="/contact">{callToActionData.btnText}<i className="icon"><FiArrowRight /></i></Link>


                                    </div>  
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFive;