import React from 'react'
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import Layout from '../common/Layout';


const CspAppSetupGuide = ({ data }) => {
    return (
        <>
         <SEO title="BitFrills | Web Development Agency | 20223 " />
           <Layout>
        <div className="main-content">
        <div className="rwt-portfolio-details rn-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                        <div className="csp-main">
                            <h1 className="heading theme-gradient">App setup help and support</h1>
                            <h5 className="subheading" style={{lineHeight:"1.5"}}>Follow these instructions to setup the app for your theme. Feel free to reach out to us at<a href="mailto:contact@bitfrills.ca"> contact@bitfrills.ca</a> in case you face any issues.</h5>
                        </div>
                             <div className="portfolio-gallery mt--40">
                             <Separator /> 
                                <div className="gallery mt--40">
                                   <div className="setup-text">
                                        <h3 className="subtitle">1. Open Customize Theme page</h3>
                                        <p className="description">Go to the Sales channels &#8811; Click on Online store </p>
                                    </div>
                                    <div className="thumbnail">
                                        <img className="radius w-100" src="../images/banner/csp1.jpg" alt="CSP App Setup" />
                                    </div>
                                </div>
                                
                                <div className="gallery mt--40">
                                   <div className="setup-text">
                                        <h3 className="subtitle">2. Open Theme settings</h3>
                                        <p className="description">Go to the Themes and select the theme on which you want to enable the color swatches. Click on the 'Customize' button for your theme.</p>
                                   </div>
                                    <div className="thumbnail">
                                    <img className="radius w-100" src="../images/banner/csp2.jpg" alt="CSP App Setup" />
                                    </div>
                                </div>

                                <div className="gallery mt--40">
                                <div className="setup-text">
                                        <h3 className="subtitle">3. Enable CSP App embeds</h3>
                                        <p className="description">Click 'App embeds Icon' at the left of the customization page. Find the app embeds for the 'Color swatch app' and enable the switch for the Collection page and/or the product page. Voila! App setup complete. Happy selling!</p>
                                   </div>
                                    <div className="thumbnail">
                                    <img className="radius w-100" src="../images/banner/csp3.jpg" alt="CSP App Setup" />
                                    </div>
                                </div>

                                <div className="gallery mt--40">
                                <div className="setup-text bg-light p-5">
                                        <p className="description">In case your theme name is not present in the above documentation list, please contact us at <a href="mailto:contact@bitfrills.ca">contact@bitfrills.ca</a> after which our support ninja will set up the app for your store. 
                                        They may need collaborators' account access to your store so please send the correct Shopify store URL as well.
                                    </p>
                                   </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
        </Layout>
        </>
    )
}

export default CspAppSetupGuide;
